import { KeaPlugin, resetContext } from 'kea'


interface InitKeaProps {
  state?: Record<string, any>
  routerHistory?: any
  routerLocation?: any
  beforePlugins?: KeaPlugin[]
}

export const loggerPlugin: () => KeaPlugin = () => ({
  name: 'verbose-kea-logger',
  events: {
    beforeReduxStore(options) {
      options.middleware.push((store) => (next) => (action) => {
        const response = next(action)
        /* eslint-disable no-console */
        console.groupCollapsed('KEA LOGGER', action)
        console.log(store.getState())
        console.groupEnd()
        /* eslint-enable no-console */
        return response
      })
    },
  },
})

export const initKea: (props: InitKeaProps) => void = (props = {}) => {
  const plugins = [
    ...(props.beforePlugins || []),
  ]
  if (process.env.NODE_ENV === 'development') plugins.push(loggerPlugin)
  resetContext({
    plugins: plugins,
    createStore: {
      compose: ((...funcs: any[]) => {
        if (funcs.length === 0) {
          return <T>(arg: T) => arg
        }
        if (funcs.length === 1) {
          return funcs[0]
        }
        return funcs.reduce(
          (a, b) =>
            (...args: any) =>
              a(b(...args))
        )
      }) as any,
    },
  })
}