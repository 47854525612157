
const allowedHosts = [
  'ielts.dauyan.net',
  'ielts.dauyan.com',
  'share.dauyan.net',
  'share.dauyan.com',
  'app.dauyan.net',
  'app.dauyan.com',
  'test.dauyan.net',
];

export function checkHost() {
  if (!allowedHosts.includes(window.location.hostname)) {
    // LOG
    setTimeout(() => {
    }, 100);
  }
}
