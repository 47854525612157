/**
 * 动态加载脚本
 * @param src
 * @returns 
 */

export const load_scripts = async (src: string): Promise<void> => {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.src = src;
    script.async = true;

    script.onload = () => {
      resolve();
    };

    script.onerror = (error) => {
      reject(new Error(`Failed to load script ${src}`));
    };

    document.head.appendChild(script);
  });
};
