import { kea } from 'kea'
import { get_home_menus_async, get_sys_menus_async } from '@/api/api.home'
import type { home_logicType } from "../../logic_types/src/logic/home/home_logicType";

export const home_logic = kea<home_logicType>({
  path: ["src", "logic", "home", "index"],

  actions: ({
    get_home_menus: (value) => ({ value }),
    get_sys_menus: (value) => ({ value }),
    set_home_menus: (value) => ({ value }),
    get_home_menus_success: (value) => ({ value }),
    get_sys_menus_success: (value) => ({ value }),
    set_default_selected_keys: (value) => ({ value }),
    set_default_open_keys: (value) => ({ value }),
    set_collapsed: (value) => ({ value })
  }),

  reducers: {
    menus: [
      [],
      {
        set_home_menus: (state, payload) => payload.value,
        get_home_menus_success: (state, payload) => payload.value,
        get_home_menus_failure: (state, payload) => [],
      },
    ],
    flatten_menu: [
      [],
      {
        get_home_menus_success: (state, payload) => {
          const list: any[] = [];
          const flatten_menu = (menus: any[]) => {
            menus.forEach(item => {
              list.push(item);
              if (item.children) {
                flatten_menu(item.children);
              }
            });
          };
          flatten_menu(payload.value);
          return list
        },
      }
    ],
    sys_menus: [
      [],
      {
        get_sys_menus_success: (state, payload) => {
          const get_select_menu_list = (menu: any[]) => {
            const process_menu = (menu: any[]) => {
              menu.forEach((item) => {
                if (item.children !== null) {
                  item['label'] = item.name;
                  item['value'] = item.id;
                  item['options'] = process_menu(item.children);
                } else {
                  item['label'] = item.name;
                  item['value'] = item.id;
                }
              });
              return menu;
            };
            process_menu(menu);
            return menu;
          }
          let next_options = [{ label: '全部', value: 0 }];
          next_options = next_options.concat(get_select_menu_list(payload.value));
          return next_options
        }
      }
    ],
    flatten_sys_menu: [
      [],
      {
        get_sys_menus_success: (state, payload) => {
          const list: any[] = [];
          const flatten_menu = (menus: any[]) => {
            menus.forEach(item => {
              list.push(item);
              if (item.children) {
                flatten_menu(item.children);
              }
            });
          };
          flatten_menu(payload.value);
          return list
        },
      }
    ],
    default_open_keys: [
      [],
      {
        set_default_open_keys: (state, payload) => {
          return payload.value
        }
      }
    ],
    default_selected_keys: [
      [],
      {
        set_default_selected_keys: (state, payload) => {
          return payload.value
        }
      }
    ],
    menus_loading: [
      true,
      {
        get_home_menus: () => true,
        get_home_menus_success: () => true,
        get_home_menus_failure: () => false
      }
    ],
    collapsed: [
      false, {
        set_collapsed: (state, payload) => {
          return payload.value
        }
      }
    ]
  },
  listeners: ({ actions }) => {
    return {
      // 获取后台管理的菜单
      get_home_menus: async () => {
        try {
          const response = await get_home_menus_async({});
          actions.get_home_menus_success(response.data)
        } catch (err) {
          console.log(err);
        }
      },
      // 获取系统的菜单
      get_sys_menus: async () => {
        try {
          const response = await get_sys_menus_async({});
          actions.get_sys_menus_success(response.data);
        } catch (err) { }
      }
    }
  },
  events: (payload) => {
    return {
      afterMount: () => { }
    }
  }
})

export { }