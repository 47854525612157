import axios from 'axios'

export interface IUserLoginPasswordRequest {
  username: string;
  password: string
}
/**
 * 用户名密码登录
 * @param params 
 * @returns 
 */
export const user_login_password = async (params: IUserLoginPasswordRequest) => {
  return await axios.post('/api/auth/login', params);
}

/**
 * 获取用户详情
 * @param params 
 * @returns 
 */
export const get_user_info_async = async (params: any) => {
  return await axios.get('/api/auth/user_info', params)
}

/**
 * 获取用户列表
 * @param params 
 * @returns 
 */
export const get_user_list_async = async (params: any) => {
  const paylaod = {
    filter: {
      mobile: params.mobile,
      account: params.account
    },
    pagination: {
      page_num: params.page_num,
      page_size: params.page_size
    }
  }
  return await axios.post('/api/user/list', paylaod)
}

/**
 * 更新用户过期时间
 * @param params 
 * @returns 
 */
export const update_user_expiration_time_async = async (params: any) => {
  return await axios.patch('/api/user/expiration', params)
}

/**
 * 创建用户
 * @param params 
 * @returns 
 */
export const create_user_async = async (params: any) => {
  return await axios.post('/api/user/create', params)
}

/**
 * 扫码登录
 */
export const qr_login_async = async (params: any) => {
  return await axios.post('/api/auth/qr_login', params);
}

/**
 * 刷新 token
 * @param params 
 * @returns 
 */
export const refresh_access_token_async = async (params: any) => {
  return await axios.post('/api/auth/refresh_access_token', params)
}

/**
 * 获取班级列表
 * @param params 
 */
export const get_edu_class_list_async = async (params: any) => {
  const paylaod = {
    filter: {},
    pagination: {
      page_num: params.page_num,
      page_size: params.page_size
    }
  }
  return await axios.post('/api/edu/get_class', paylaod)
}

/**
 * 获取班级下面的全部老师
 * @param params 
 * @returns 
 */
export const get_edu_teacher_list_by_class_async = async (params: any) => {
  const paylaod = {
    filter: {
      class_id: params.class_id
    },
    pagination: {
      page_num: params.page_num,
      page_size: params.page_size
    }
  }
  return await axios.post('/api/edu/get_class_teacher', paylaod)
}

/**
 * 更新用户的教师状态
 * @param params 
 * @returns 
 */
export const update_user_teacher_status_async = async (params: any) => {
  return await axios.post('/api/admin/user/update_teacher_status', params)
}

/**
 * 获取班级下面的全部学生
 * @param params 
 * @returns 
 */
export const update_user_student_status_async = async (params: any) => {
  const paylaod = {
    filter: {
      class_id: params.class_id
    },
    pagination: {
      page_num: params.page_num,
      page_size: params.page_size
    }
  }
  return await axios.post('/api/edu/get_class_student', paylaod)
}

/**
 * 添加学生
 * @param params 
 */
export const update_edu_class_student_async = async (params: any) => {
  return await axios.post('/api/edu/update_class_student', params)
}

/**
 * 添加老师
 * @param params 
 * @returns 
 */
export const update_edu_class_teacher_async = async (params: any) => {
  return await axios.post('/api/edu/update_class_teacher', params)
}

/**
 * 删除班级
 * @param params 
 * @returns 
 */
export const delete_edu_class_async = async (params: any) => {
  return await axios.post('/api/edu/delete_class', params)
}

/**
 * 创建班级
 * @param params 
 * @returns 
 */
export const create_class_async = async (params: any) => {
  return await axios.post('/api/edu/create_class', params)
}

/**
 * 移除教师
 * @param params 
 * @returns 
 */
export const remove_teacher_async = async (params: any) => {
  return await axios.post('/api/edu/remove_teacher', params)
}

/**
 * 移除学生
 * @param params 
 * @returns 
 */
export const remove_student_async = async (params: any) => {
  return await axios.post('/api/edu/remove_student', params)
}