import axios from 'axios'

/**
 * 获取菜单
 * @param params 
 * @returns 
 */
export const get_home_menus_async = async (params: any) => {
  return await axios.get('/api/menu/list', params)
}

/**
 * 获取系统菜单
 * @param params 
 * @returns 
 */
export const get_sys_menus_async = async (params: any) => {
  return await axios.get('/api/menu/sys_menu_list', params)
}