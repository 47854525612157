import React, { useEffect } from 'react'
import { useActions, useValues } from 'kea'
import { home_logic } from '@/logic/home/home_logic'
import { user_logic } from '@/logic/user/user_logic'
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from '@ant-design/icons';
import { Layout, Dropdown } from 'antd';
import { HomeMenus } from 'src/components/Menu'
import { Routes, Route } from 'react-router'
import { routers } from './Router'
import { UserOutlined } from '@ant-design/icons';
import { Avatar } from 'antd';
import type { MenuProps } from 'antd';
import classnames from 'classnames'
import css from './index.module.less'

const { Header, Sider, Content } = Layout;

export const Home: React.FC = () => {
  const { collapsed } = useValues(home_logic)
  const { get_home_menus, get_sys_menus, set_collapsed } = useActions(home_logic);
  const { logout } = useActions(user_logic)

  useEffect(() => {
    get_home_menus({})
    get_sys_menus({})
  }, [])

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <span onClick={() => {
          logout({});
        }}>
          退出登录
        </span>
      ),
    },
  ];

  return (
    <Layout className={classnames(css['eadmin-layout'])}>
      <Sider className={classnames(css['eadmin-sider'])} trigger={null} collapsible collapsed={collapsed} theme="light" collapsedWidth={0}>
        <HomeMenus collapsed={collapsed} />
      </Sider>

      <Layout className="site-layout">
        <Header className={classnames(css['eadmin-header'])}>
          {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
            className: classnames(css['eadmin-trigger']),
            onClick: () => set_collapsed(!collapsed),
          })}
          <div className={classnames(css['eadmin-avatar'])}>
            <Dropdown menu={{ items }} placement="bottomLeft">
              <Avatar size={30} icon={<UserOutlined />} />
            </Dropdown>
          </div>
        </Header>

        <Content className={classnames(css['site-layout-background'], css['content'])}>
          <Routes>
            {
              routers.map((item) => {
                return <Route key={item.path} {...item} />;
              })
            }
          </Routes>
        </Content>
      </Layout>
    </Layout>
  )
}
