import React from 'react';
import { user_logic } from '@/logic/user/user_logic'
import { useValues } from 'kea'
import { Home } from '@/modules/Home'
import { ConfigProvider } from 'antd';
import zhCN from 'antd/lib/locale/zh_CN'; //
function App() {
  useValues(user_logic)
  return (
    <div className="eadmin-container">
      <ConfigProvider locale={zhCN}>
        <Home />
      </ConfigProvider>
    </div>
  );
}
export default App;
