import axios, { AxiosResponse } from 'axios';
import { refresh_access_token_async } from 'src/api/api.user'
import jsCookie from 'js-cookie';
import { toast } from 'react-hot-toast'

export const ACCESS_TOKEN_KEY = 'eadmin_access_token';
export const REFRESH_TOKEN_KEY = 'eadmin_refresh_token';

let refresh_access_token_num = 0
const refresh_access_token = async () => {
  if (refresh_access_token_num === 0) {
    refresh_access_token_num += 1;
    const refresh_access_token = jsCookie.get(REFRESH_TOKEN_KEY) || window.localStorage.getItem(REFRESH_TOKEN_KEY);
    const response = await refresh_access_token_async({
      grant_type: "refresh_token",
      refresh_token: refresh_access_token
    });
    // 设置 token
    window.localStorage.setItem(ACCESS_TOKEN_KEY, response.data.access_token);
    window.localStorage.setItem(REFRESH_TOKEN_KEY, response.data.refresh_token)
    // 设置 cookie
    jsCookie.set(ACCESS_TOKEN_KEY, response.data.access_token);
    jsCookie.set(REFRESH_TOKEN_KEY, response.data.refresh_token);
    toast.success("页面即将刷新");
    setTimeout(() => {
      window && window.location.reload();
    }, 500);
  }
}

axios.interceptors.request.use(
  function (config) {
    const token = jsCookie.get(ACCESS_TOKEN_KEY) || window.localStorage.getItem(ACCESS_TOKEN_KEY);
    config.headers.Authorization = config?.headers?.Authorization ?? (`Bearer ${token}` || '');
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response: AxiosResponse) => {
    const { message: errorMessage, status_code } = response.data;
    if (status_code === 200 || status_code === 201) {
      return response.data;
    } else {
      if (status_code === 4001 && window.location.pathname !== '/login') {
        //  window.location.href = "/login";
      }
      // token 验证不正确跳转到登录
      if (status_code === 4002) {
        window.location.href = "/error";
      }
      if (status_code === 4003) {
        refresh_access_token();
      }
      return Promise.reject({ ...response.data, error: errorMessage, header: response.headers, config: response.config });
    }
  },
  function (error) {
    if (axios.isCancel(error)) {
      return Promise.resolve(error);
    } else {
      return Promise.reject(error);
    }
  }
);