import { RouteProps } from 'react-router-dom';
import { NotFind } from '@/components/NotFind';
import { withLazy } from '@/components/Lazy'

export const Home = withLazy(() => import('@/modules/Home').then((res) => res.Home));
export const FBOperListLazy = withLazy(() => import('@/modules/FB_Operation/List').then((res) => res.FBOperList));
export const FBOperDetailLazy = withLazy(() => import('@/modules/FB_OperationDetail').then((res) => res.FBOperationDetail));
export const FBCorrListLazy = withLazy(() => import('@/modules/FB_Correction/List').then((res) => res.FBCorrList));
export const FBCoorDetailLazy = withLazy(() => import('@/modules/FB_CorrectionDetail').then((res) => res.FBCorrDetail));
export const UserListLazy = withLazy(() => import('@/modules/User/UserList').then((res) => res.UserList));
export const RedemptionCodeListLazy = withLazy(() => import('@/modules/Billing/RedemptionCode/List').then((res) => res.List));
export const RoleListLazy = withLazy(() => import('@/modules/Permission/Role/List').then((res) => res.List));
export const AdminUserLazy = withLazy(() => import('@/modules/Permission/AdminUser/List').then((res) => res.List));
export const UserClassLazy = withLazy(() => import('@/modules/Class/List').then((res) => res.List));
export const LmsCourseListLazy = withLazy(() => import('@/modules/Lms/List').then((res) => res.List));
export const LmsCourseDetailLazy = withLazy(() => import('@/modules/Lms/Detail').then((res) => res.Detail));

export const routers: RouteProps[] = [
  { path: '/', Component: FBOperListLazy },
  { path: '/fb/operation', Component: FBOperListLazy },
  { path: '/fb/operation/:id', Component: FBOperDetailLazy },
  { path: '/fb/correction', Component: FBCorrListLazy },
  { path: '/fb/correction/:id', Component: FBCoorDetailLazy },
  { path: '/user/list', Component: UserListLazy },
  { path: '/billing/redemption_code_list', Component: RedemptionCodeListLazy },
  { path: '/permission/role_list', Component: RoleListLazy },
  { path: '/permission/admin_user_list', Component: AdminUserLazy },
  { path: '/user/class', Component: UserClassLazy },
  { path: '/lms/list', Component: LmsCourseListLazy },
  { path: "/lms/detail", Component: LmsCourseDetailLazy },
  { path: '/*', Component: NotFind },
]