

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import { checkHost } from './checkHost';
import { initKea } from './initKea';
import { uoload_oss_sdk } from './initOSS'
import 'src/styles/vars.less'

checkHost();
initKea({});
uoload_oss_sdk();


setTimeout(() => {
  const loadingDom = document.getElementById('page_loading');
  if (!loadingDom) {
    // todo
  }
}, 20 * 1000);


document.addEventListener('gesturestart', function (e) {
  e.preventDefault(); // 阻止缩放手势
});