import React from 'react';
import '@/utils/init';
import { HashRouter } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import App from './App';
import { Toaster } from 'react-hot-toast';
import './index.less'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.Suspense>
    <HashRouter>
      <App />
      <Toaster />
    </HashRouter>
  </React.Suspense >
);

