import { kea } from 'kea'
import { user_login_password, get_user_info_async, qr_login_async, update_user_teacher_status_async } from '@/api/api.user';
import { user_list_logic } from './user_list_logic'
import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from '@/utils/network/axios-init'
import { toast } from 'react-hot-toast'
import qs from 'query-string'
import cookie from 'js-cookie'

import type { user_logicType } from "../../logic_types/src/logic/user/user_logicType";

export const EXCLUDE_PAGES = ['/login']

export const user_logic = kea<user_logicType>({
  path: ["src", "logic", "user", "user_logic"],

  connect: {
    logic: [user_list_logic],
    actions: [user_list_logic, ['get_user_list']]
  },

  actions: () => ({
    set_access_token: (value) => ({ value }),
    get_user_info_login_password: (value) => ({ value }),
    get_user_info: (value) => ({ value }),
    set_user_info: (value) => ({ value }),
    load_user_token_sucess: (value) => ({ value }),
    load_user_token_sucess_failure: (error) => ({ error }),
    logout: (value) => ({ value }),
    lark_qr_login: () => { },
    get_lark_qr_login_code: () => { },
    update_user_teacher_status: (value) => ({ value }),
    update_user_teacher_status_success: () => { },
    update_user_teacher_status_failure: () => { }
  }),

  reducers: {
    use_qr_login: [
      true
    ],
    access_token: [
      "",
      {
        set_access_token: (state, payload) => payload.value
      }
    ],
    user_info: [
      null,
      {
        set_user_info: (state, payload) => payload.value
      }
    ],
    login_loading: [
      false,
      {
        get_user_info_login_password: () => true,
        load_user_token_sucess: () => true,
        load_user_token_sucess_failure: () => false
      }
    ],
    update_user_teacher: [
      null, {
        update_user_teacher_status: (state: any, payload) => payload.value,
        update_user_teacher_status_success: () => null,
        update_user_teacher_status_failure: () => null
      }
    ]
  },

  listeners: ({ actions, values }) => ({
    // 使用飞书扫码登录
    lark_qr_login: async () => {
      const params = qs.parseUrl(window.location.href);
      const query = params.query
      const code = query.code;
      if (code) {
        try {
          // 拿到了 code 进行 code 换 token 的操作
          const redirect_uri = `${window.location.origin}/login`;
          const { data } = await qr_login_async({
            loginTime: `${new Date().getTime()}`,
            redirect_uri: redirect_uri,
            url: window.location.href,
            code: code
          }) as any;
          actions.set_user_info(data.user_info);
          actions.load_user_token_sucess(true);
          // 设置 token
          window.localStorage.setItem(ACCESS_TOKEN_KEY, data.token_info.access_token);
          window.localStorage.setItem(REFRESH_TOKEN_KEY, data.token_info.refresh_token)
          // 设置 cookie
          cookie.set(ACCESS_TOKEN_KEY, data.token_info.access_token);
          cookie.set(REFRESH_TOKEN_KEY, data.token_info.refresh_token);
          toast.success("登录成功即将跳转");
          window.location.href = `${window.location.protocol}//${window.location.host}/`
        } catch (err: any) {
          toast.error(err.message)
        }
      } else {
        actions.get_lark_qr_login_code();
      }
    },
    // 获取二维码以及code
    get_lark_qr_login_code: async () => {
      try {
        const app_id = 'cli_a66eb6fcfc7a9013';
        const redirect_uri = `${window.location.origin}/login`;
        const goto_url = `https://passport.feishu.cn/suite/passport/oauth/authorize?client_id=${app_id}&redirect_uri=${encodeURI(redirect_uri)}&response_type=code&state=success_login`;
        const qr_login_object = (window as any).QRLogin({
          id: "lark_qr_container",
          goto: `${goto_url}`,
          style: "width: 300px; height: 300px; margin-left:18px; border: 0; background-color: #E6F4F3; background-size: cover",
        });

        // 触发的回调用函数
        let handle_message = function (event: any) {
          var origin = event.origin;
          if (qr_login_object.matchOrigin(origin)
            && window.location.href.indexOf('/login') > -1
          ) {
            var login_tmp_code = event.data;
            window.location.href = `${goto_url}&tmp_code=${login_tmp_code.tmp_code}`;
          }
        };
        if (typeof window.addEventListener !== 'undefined') {
          window.addEventListener('message', handle_message, false);
        }
        else if (typeof (window as any).attachEvent !== 'undefined') {
          (window as any).attachEvent('onmessage', handle_message);
        }
      } catch (err) {
        toast.error("获取二维码失败")
      }
    },
    // 获取用户信息（登录）
    get_user_info_login_password: async (params) => {
      try {
        // 通过用户名密码登录
        const response = await user_login_password(params.value);
        // 临时判断以后希望该用拦截器，但需要后端接口的 code 码统一
        const keys = Object.keys(response.data)
        if (keys.length === 0) {
          throw new Error("server error");
        } else {
          actions.set_user_info(response.data);
          actions.load_user_token_sucess(true);
          // 设置 token
          window.localStorage.setItem(ACCESS_TOKEN_KEY, response.data.access_token);
          // 设置 cookie
          cookie.set(ACCESS_TOKEN_KEY, response.data.access_token);
          toast.success("登录成功即将跳转");
          window.location.href = `${window.location.protocol}//${window.location.host}/`
        }
      } catch (err) {
        toast.error("账号密码错误");
        actions.load_user_token_sucess_failure(false);
      }
    },
    // 获取用户信息
    get_user_info: async (params) => {
      try {
        const user_info = await get_user_info_async({});
        // token
        const token = window.localStorage.getItem(ACCESS_TOKEN_KEY);
        // 设置 cookie
        token && cookie.set(ACCESS_TOKEN_KEY, token);
        actions.set_user_info(user_info.data);
      } catch (err) { }
    },
    // 退出登录
    logout: () => {
      // token
      const token = window.localStorage.getItem(ACCESS_TOKEN_KEY);
      // 设置 cookie
      token && cookie.remove(ACCESS_TOKEN_KEY);
      token && cookie.remove(REFRESH_TOKEN_KEY);
      window.localStorage.removeItem(ACCESS_TOKEN_KEY);
      window.localStorage.removeItem(REFRESH_TOKEN_KEY)
      toast.success("登录成功即将跳转");
      window.location.href = `${window.location.protocol}//${window.location.host}/login`
    },
    // 更新用户的教师状态
    update_user_teacher_status: async (params) => {
      try {
        const response = await update_user_teacher_status_async(params.value);
        if ((response as any).status_code === 200) {
          actions.update_user_teacher_status_success();
          actions.get_user_list(true);
          toast.success("更新教师状态成功")
        } else {
          throw new Error("更新教师状态失败");
        }
      } catch (err) {
        toast.error("更新教师状态失败")
      }
    }
  }),

  events: (paylaod) => {
    return {
      afterMount: () => {
        // 获取当前所处的页面
        const curr_page = window.location.hash;
        // 内存中的 access_token
        const { access_token } = paylaod.values;
        // 本地中的 access_toekn
        const access_token_local = window.localStorage.getItem(ACCESS_TOKEN_KEY);
        // 如果当前页面是排除页面，不做任何操作,临时
        if (EXCLUDE_PAGES.includes(curr_page)) return;
        // 如果本地和内存中都没有 token，直接跳转登录
        if (!access_token && !access_token_local) {
          return;
        }
        // 如果有 token，就要校验 token 的有效性，如果 token 无效，跳转登录
        paylaod.actions.get_user_info({});
      }
    }
  }
});